// Main Style
@import 'foundation/foundation';
@import 'layout/variable_mixin_function';

.notfound {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	.img-wrap {
		width: 100%;
		max-width: rem-calc(200);
		margin-bottom: rem-calc(20);

		@include breakpoint(large) {
			max-width: rem-calc(277);
			margin-bottom: rem-calc(30);
		}

		.object-fit {
			&::before {
				padding-top: 75.81%;
			}
		}
	}

	&-title {
		color: #000;
		font-weight: 500;
		margin-bottom: rem-calc(20);
		@include breakpoint(large) {
			margin-bottom: rem-calc(40);
		}
	}

	&-text {
		color: #000;
		font-weight: 300;
	}

	.btn {
		font-size: rem-calc(18);
		line-height: 1.5;
		min-height: rem-calc(44);
		min-width: rem-calc(125);
	}
}
